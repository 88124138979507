import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import dayjs from "dayjs";
import moment from "moment";

const handleDownloadEmployeeExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchEmployeeData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`employee/get_employees?sortBy=name&sortOrder=ASC`)
        .then((response) => {
          if (response?.data) {
            const employeeData = response?.data?.data;
            resolve(employeeData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const employeeData = await fetchEmployeeData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Employee");

    // Add headers
    worksheet.addRow([
      "Profile Image",
      "ID",
      "Name",
      "Employee Code",
      "Role",
      "Email",
      "Time",
      "Bithday",
      "Date of Joining",
      "Phone Number",
      "Workdays",
      "Company",
      "Location",
      "Milestone",
      "Activities",
      "Tasks",
      "Department",

    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" };

    if (
      employeeData !== null &&
      employeeData !== undefined &&
      employeeData.length > 0
    ) {
      employeeData.forEach((employee) => {
        worksheet.addRow([
          employee?.profile_photo || "",
          employee?.employee_code || "",
          employee?.name || "",
          employee?.employee_code || "",
          employee?.role_name || "",
          employee?.email || "",
          employee?.start_time && employee?.end_time
            ? `${dayjs(employee.start_time, "HH:mm:ss").format(
                "h:mm A"
              )} To ${dayjs(employee.end_time, "HH:mm:ss").format("h:mm A")}`
            : "",
          employee?.birthday || "",
          employee?.date_of_joining || "",
          employee?.phone || "",
          [
            employee?.sunday_working_status === 1 && "Sunday",
            employee?.monday_working_status === 1 && "Monday",
            employee?.tuesday_working_status === 1 && "Tuesday",
            employee?.wednesday_working_status === 1 && "Wednesday",
            employee?.thursday_working_status === 1 && "Thursday",
            employee?.friday_working_status === 1 && "Friday",
            employee?.saturday_working_status === 1 && "Saturday",
          ]
            .filter((day) => day)
            .join(", ") || "--",
          employee?.company_name || "",
          employee?.location_name || "",
          employee?.milestones?.length > 0
          ? employee?.milestones.map((milestone) => milestone?.name || "--").join(", ")
          : "",
          employee?.activities?.length > 0
          ? employee?.activities.map((activities) => activities?.name || "--").join(", ")
          : "",
          employee?.tasks?.length > 0
          ? employee?.tasks.map((task) => task?.name || "--").join(", ")
          : "",
          employee?.departments?.length > 0
          ? employee?.departments.map((departments) => departments?.name || "--").join(", ")
          : "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `EmployeeData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadEmployeeExcel };
