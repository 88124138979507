import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import { displayDuration } from "../../Mixin/DisplayDuration";
import moment from "moment";

const handleDownloadMilestoneExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchMilestoneData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`milestone/get_milestones`)
        .then((response) => {
          if (response?.data) {
            const milestoneData = response?.data?.data;
            resolve(milestoneData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching milestone data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const milestoneData = await fetchMilestoneData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Milestone");

    // Add headers
    worksheet.addRow([
      "Name",
      "Duration",
      "Activities",
      // "Files",
      "Company",
      "Location",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (
      milestoneData !== null &&
      milestoneData !== undefined &&
      milestoneData.length > 0
    ) {
      milestoneData.forEach((milestone) => {
        worksheet.addRow([
          milestone?.name || "",
          displayDuration(milestone.days, milestone.hours, milestone.minutes) ||
            "",
          milestone?.activities?.length
            ? milestone?.activities?.[0]?.tasks
                .map((task) => task.task_name)
                .join(", ")
            : "",
          // milestone?.attached?.length > 0 ? milestone.attached.join(", ") : "",
          milestone?.company_name || "",
          milestone?.location_name || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `MilestoneData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadMilestoneExcel };
